import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { FormsModules } from '@utils/shared-modules';

@Component({
  selector: 'app-search-box',
  standalone: true,
  templateUrl: './search-box.component.html',
  styleUrl: './search-box.component.scss',
  imports: [CommonModule, TranslateModule, FormsModules],
})
export class SearchBoxComponent implements AfterViewInit {
  @ViewChild('searchTermInput', { static: true }) searchTermInput!: ElementRef;
  @ViewChild(MatAutocomplete) autocomplete!: MatAutocomplete;

  @Input() debounceTime = 200;

  @Input() placeholder = 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SEARCH_PRODUCTS';

  @Input() roundedCorners!: boolean;

  @Input() size: 'normal' | 'large' = 'normal';

  @Input() filteredProductNames?: string[] = [];

  @Output() valueChanged: EventEmitter<string> = new EventEmitter();

  isSearchTermInputEmpty: boolean = true;
  selectedOption: any;

  ngAfterViewInit() {
    fromEvent<Event>(this.searchTermInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap((e: Event) => {
          const target = e.target as HTMLInputElement;
          const searchTerm = target.value;
          this.isSearchTermInputEmpty = this.isSearchTermEmpty();
          this.valueChanged.emit(searchTerm);
        }),
      )
      .subscribe();
  }

  clearInput(): void {
    // Clear the selected option in the MatAutocomplete
    if (this.autocomplete && this.selectedOption) {
      this.selectedOption.deselect();
      this.selectedOption = null;
    }

    this.searchTermInput.nativeElement.value = '';
    this.isSearchTermInputEmpty = this.isSearchTermEmpty();
    this.valueChanged.emit('');
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOption = event.option;
    this.isSearchTermInputEmpty = this.isSearchTermEmpty();
    this.valueChanged.emit(event.option.viewValue);
  }

  isSearchTermEmpty(): boolean {
    return this.searchTermInput.nativeElement.value.length === 0;
  }

  focus() {
    this.searchTermInput.nativeElement.focus();
  }
}
