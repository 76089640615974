<div class="search-box-wrapper">
  <i class="fal fa-search search-icon"></i>
  <input
    #searchTermInput
    [matAutocomplete]="auto"
    [placeholder]="placeholder | translate"
    class="search-box"
  />

  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option *ngFor="let option of filteredProductNames" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>

  <div class="x-mark-icon">
    <i
      class="far fa-times-circle"
      (click)="clearInput()"
      *ngIf="!isSearchTermInputEmpty"
    ></i>
  </div>
</div>
